var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.platFormTitle,
            visible: _vm.dialogFormVisible,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents1" },
            [
              _c(
                "el-form",
                {
                  ref: "platForm",
                  attrs: {
                    model: _vm.platForm,
                    rules: _vm.rules,
                    "label-width": "auto",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "添加商品", prop: "sku_codes" } },
                    _vm._l(_vm.skuCodeList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "d-flex a-center mb-10" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                remote: "",
                                placeholder: "请输入商品名称/货号",
                                "remote-method": _vm.remoteMethod,
                                loading: _vm.loading,
                                clearable: "",
                              },
                              on: {
                                "visible-change": _vm.onChangeVisible,
                                change: _vm.change,
                              },
                              model: {
                                value: item.value,
                                callback: function ($$v) {
                                  _vm.$set(item, "value", $$v)
                                },
                                expression: "item.value",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.skuCode,
                                attrs: {
                                  label: `${item.title}【${item.skuCode}】【${
                                    item?.supplier?.title || ""
                                  }】`,
                                  value: item.skuCode,
                                },
                              })
                            }),
                            1
                          ),
                          _c("div", { staticStyle: { margin: "0px 10px" } }, [
                            _c("i", {
                              staticClass: "el-icon-plus",
                              staticStyle: { color: "#13ae67" },
                              on: { click: _vm.addOption },
                            }),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                margin: "0px 10px",
                                width: "30px",
                              },
                            },
                            [
                              index != 0
                                ? _c("i", {
                                    staticClass: "el-icon-delete",
                                    staticStyle: { color: "#13ae67" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteOption(item)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submitFrom },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }