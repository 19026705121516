<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column
      type="index"
      width="80"
      align="center"
      label="序号"
    ></el-table-column>
    <el-table-column
      prop="logisticBusinessName"
      align="center"
      min-width="120"
      label="集配中心"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="kv" align="center" label="商品主图">
      <template slot-scope="scope" v-if="scope.row.kv">
        <el-image
          style="width: 30px; height: 30px"
          :src="scope.row.kv"
          :preview-src-list="[scope.row.kv]"
        ></el-image>
      </template>
    </el-table-column>
    <el-table-column prop="title" align="center" label="商品名称">
    </el-table-column>
    <el-table-column prop="skuCode" align="center" label="货号">
    </el-table-column>
    <el-table-column
      prop="grade"
      align="center"
      label="等级"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="grossWeight"
      align="center"
      label="毛重"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="grossWeightPrice"
      align="center"
      label="毛单价"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="weight"
      align="center"
      label="净重"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="weightPrice"
      align="center"
      label="净重价"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="price"
      align="center"
      label="开市价"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="supplier" align="center" label="供货商">
      <template slot-scope="scope">
        <span>{{ scope.row.supplier.title }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="buyer" align="center" label="采购员">
      <template slot-scope="scope">
        <span>{{ scope.row.buyer.fullname }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="createTime" align="center" label="创建时间">
    </el-table-column>
    <el-table-column prop="productAddTime" align="center" label="添加时间">
    </el-table-column>
    <el-table-column prop="productDeleteTime" align="center" label="删除时间">
    </el-table-column>
    <el-table-column prop="sales" align="center" label="在售期间销量">
    </el-table-column>
    <el-table-column
      prop="sortNum"
      align="center"
      label="排序"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <el-input
          v-model="scope.row.sortNum"
          placeholder="请排序"
          @blur="() => changeSort(scope.row)"
        ></el-input>
      </template>
    </el-table-column>

    <el-table-column prop="stock" align="center" label="库存数量">
    </el-table-column>
    <el-table-column
      prop="purchase_deduction_amount"
      align="center"
      label="操作"
    >
      <template slot-scope="scope">
        <el-button type="text" @click="onHandleDelete(scope.row)">
          删除
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {
  postVersatileRegionProductDelete,
  postVersatileRegionBindProductSort,
} from "@/api/generalControl/zone";
export default {
  name: "TableList",
  props: ["tableData", "id"],
  data() {
    return {};
  },
  methods: {
    /**
     * 设置排序
     */
    async changeSort(row) {
      try {
        const res = await postVersatileRegionBindProductSort({
          id: this.id,
          sku_code: row.skuCode,
          sort_num: row.sortNum,
        });
        this.$message({
          type: "success",
          message: "排序成功",
        });
        this.$emit("submit-form");
      } catch (error) {
        console.log(error, "postVersatileRegionBindProductSort");
      }
    },
    /**
     * 删除
     */
    onHandleDelete(row) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          try {
            const res = await postVersatileRegionProductDelete({
              id: this.id,
              sku_code: row.skuCode,
            });
            this.$message({
              type: "success",
              message: "已删除",
            });
            this.$emit("submit-form");
          } catch (error) {}
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
