/** 商品状态枚举 */
export const GOODS_STATUS_ENUM = {
  all: {
    value: "all",
    label: "全部",
  },
  0: {
    value: 0,
    label: "正常",
  },
  1: {
    value: 1,
    label: "删除",
  },
};
