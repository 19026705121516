<template>
  <div>
    <!--  添加关联商品弹出框  -->
    <el-dialog
      :title="platFormTitle"
      :visible.sync="dialogFormVisible"
      width="60%"
      class="dialog"
      center
    >
      <div class="contents1">
        <el-form
          :model="platForm"
          :rules="rules"
          ref="platForm"
          label-width="auto"
        >
          <el-form-item label="添加商品" prop="sku_codes">
            <div
              v-for="(item, index) in skuCodeList"
              :key="index"
              class="d-flex a-center mb-10"
            >
              <el-select
                v-model="item.value"
                filterable
                remote
                placeholder="请输入商品名称/货号"
                :remote-method="remoteMethod"
                @visible-change="onChangeVisible"
                :loading="loading"
                @change="change"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.skuCode"
                  :label="`${item.title}【${item.skuCode}】【${
                    item?.supplier?.title || ''
                  }】`"
                  :value="item.skuCode"
                >
                </el-option>
              </el-select>
              <div style="margin: 0px 10px">
                <i
                  class="el-icon-plus"
                  style="color: #13ae67"
                  @click="addOption"
                ></i>
              </div>
              <div style="margin: 0px 10px; width: 30px">
                <i
                  class="el-icon-delete"
                  style="color: #13ae67"
                  @click="deleteOption(item)"
                  v-if="index != 0"
                ></i>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom" :loading="submitLoading"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import {
  postVersatileRegionProductSearch,
  postVersatileRegionProductAdd,
} from "@/api/generalControl/zone";

export default {
  props: ["id"],
  name: "associationConfigDialog",
  data() {
    return {
      options: [],
      loading: false,
      submitLoading: false,
      dialogFormVisible: false, //编辑弹窗
      platFormTitle: "", //弹框title
      platFormClone: {}, // 复制数据，用于初始化
      platForm: {
        sku_codes: "",
      },
      skuCodeList: [{ value: "" }],
      rules: {
        sku_codes: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: function (rule, value, callback) {
              if (value == "") {
                callback(new Error("请输入商品名称/货号"));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  created() {
    this.platFormClone = cloneDeep(this.platForm);
  },
  methods: {
    /**
     * 下拉框出现/隐藏时触发
     */
    onChangeVisible(e) {
      if (!e) {
        this.options = [];
      }
    },
    /**
     * 下拉框改变
     */
    change(e) {
      let tmpSkuCode = this.skuCodeList.map((obj) => obj.value);
      this.platForm.sku_codes = tmpSkuCode.join(",");
      this.$refs.platForm.validateField("sku_codes");
    },
    /**
     * 添加input 选择商品
     */
    addOption() {
      this.skuCodeList.push({
        value: "",
      });
    },
    /**
     * 删除input 选择商品
     */
    deleteOption(item) {
      var index = this.skuCodeList.indexOf(item);
      if (index !== -1) {
        this.skuCodeList.splice(index, 1);
      }
    },
    /**
     *下拉框远程搜索
     */
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.getOptionsList(query);
      } else {
        this.options = [];
      }
    },
    /**
     *获取商品列表
     */
    getOptionsList: debounce(async function (e) {
      try {
        const res = await postVersatileRegionProductSearch({
          id: this.id,
          product_name_or_sku_code: e,
        });
        this.options = res.data;
      } catch (error) {
        console.log(error, "postVersatileRegionProductSearch");
      } finally {
        this.loading = false;
      }
    }, 300),
    /**
     *初始化数据
     */
    initData() {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs.platForm.clearValidate();
      });
      this.skuCodeList = [{ value: "" }];
    },
    /**
     *新增
     */
    addData() {
      this.platFormTitle = "添加商品";
      this.platForm = {
        ...this.platFormClone,
      };
      this.initData();
    },
    /**
     * 提交表单
     */
    submitFrom: debounce(function () {
      // 判断下拉选择商品是否都填写
      let tmpArr = [];
      this.skuCodeList.map((item) => {
        if (item.value == "") {
          tmpArr.push(item);
        }
      });
      if (tmpArr.length > 0) {
        return this.tool.message("请选择商品", "error");
      }
      this.$refs.platForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        let tmpSkuCode = this.skuCodeList.map((obj) => obj.value); // 获取所有商品skuCode
        try {
          await postVersatileRegionProductAdd({
            id: this.id,
            sku_codes: tmpSkuCode.join(","),
          });
          this.tool.message("添加成功", "success");
          this.$emit("submit-form"); // 刷新列表
        } catch (error) {
          console.error("postVersatileRegionProductAdd", error);
        } finally {
          this.dialogFormVisible = false;
          this.submitLoading = false;
        }
      });
    }, 300),
  },
};
</script>
<style scoped lang="scss">
.contents1 {
  box-sizing: border-box;
  padding: 10px 26px 40px;

  .hide {
    /deep/ .el-upload--picture-card {
      display: none;
    }
  }
  .tipWrap {
    color: red;
    margin-left: 10px;
  }
}
</style>
