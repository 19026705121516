var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: { type: "index", width: "80", align: "center", label: "序号" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "logisticBusinessName",
          align: "center",
          "min-width": "120",
          label: "集配中心",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "kv", align: "center", label: "商品主图" },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (scope) {
                return scope.row.kv
                  ? [
                      _c("el-image", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: {
                          src: scope.row.kv,
                          "preview-src-list": [scope.row.kv],
                        },
                      }),
                    ]
                  : undefined
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("el-table-column", {
        attrs: { prop: "title", align: "center", label: "商品名称" },
      }),
      _c("el-table-column", {
        attrs: { prop: "skuCode", align: "center", label: "货号" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "grade",
          align: "center",
          label: "等级",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "grossWeight",
          align: "center",
          label: "毛重",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "grossWeightPrice",
          align: "center",
          label: "毛单价",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "weight",
          align: "center",
          label: "净重",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "weightPrice",
          align: "center",
          label: "净重价",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "price",
          align: "center",
          label: "开市价",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "supplier", align: "center", label: "供货商" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.supplier.title))])]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "buyer", align: "center", label: "采购员" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.buyer.fullname))])]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "createTime", align: "center", label: "创建时间" },
      }),
      _c("el-table-column", {
        attrs: { prop: "productAddTime", align: "center", label: "添加时间" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "productDeleteTime",
          align: "center",
          label: "删除时间",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "sales", align: "center", label: "在售期间销量" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "sortNum",
          align: "center",
          label: "排序",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("el-input", {
                  attrs: { placeholder: "请排序" },
                  on: { blur: () => _vm.changeSort(scope.row) },
                  model: {
                    value: scope.row.sortNum,
                    callback: function ($$v) {
                      _vm.$set(scope.row, "sortNum", $$v)
                    },
                    expression: "scope.row.sortNum",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "stock", align: "center", label: "库存数量" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "purchase_deduction_amount",
          align: "center",
          label: "操作",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleDelete(scope.row)
                      },
                    },
                  },
                  [_vm._v(" 删除 ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }